const setIcon = (el) => {
  let tag = el.favicon;
  let prefixName = "";
  switch (el.type) {
    case "al":
      prefixName = "#custom-icon-";
      break;
    case "img":
      prefixName = "#icon-svg-";
      break;
    default:
      prefixName = "#custom-icon-";
  }
  if (el.isSetColor) {
    switch (el.type) {
      case "al":
        prefixName = "#icon-";
        break;
      case "img":
        prefixName = "#icon-svg_clear-";
        break;
      default:
        prefixName = "#icon-";
    }
  }

  if (prefixName) {
    prefixName = prefixName + tag;
    if (el.type === "ele") {
      tag = `<i class="chrome-i-icon ${prefixName}"></i>`;
    } else {
      tag = `<svg class="chrome-icon" aria-hidden="true">
              <use xlink:href="${prefixName}"></use>
            </svg>`;
    }
  }

  return tag;
};

((window, factory) => {
  // eslint-disable-next-line no-undef
  if (typeof define == "function" && define.amd) {
    // eslint-disable-next-line no-undef
    define(["draggabilly"], (Draggabilly) => factory(window, Draggabilly));
  } else if (typeof module == "object" && module.exports) {
    module.exports = factory(window, require("draggabilly"));
  } else {
    window.ChromeTabs = factory(window, window.Draggabilly);
  }
})(window, (window, Draggabilly) => {
  const TAB_CONTENT_MARGIN = 9;
  const TAB_CONTENT_OVERLAP_DISTANCE = 1;

  const TAB_OVERLAP_DISTANCE = TAB_CONTENT_MARGIN * 2 + TAB_CONTENT_OVERLAP_DISTANCE;

  const TAB_CONTENT_MIN_WIDTH = 24;
  const TAB_CONTENT_MAX_WIDTH = 240;

  const TAB_SIZE_SMALL = 84;
  const TAB_SIZE_SMALLER = 60;
  const TAB_SIZE_MINI = 48;

  const noop = (_) => {};

  const closest = (value, array) => {
    let closest = Infinity;
    let closestIndex = -1;

    array.forEach((v, i) => {
      if (Math.abs(value - v) < closest) {
        closest = Math.abs(value - v);
        closestIndex = i;
      }
    });

    return closestIndex;
  };

  const tabTemplate = `
    <div class="chrome-tab no-drag">
      <!-- 线 -->
      <div class="chrome-tab-dividers"></div>
      <!-- 选中背景 -->
      <div class="chrome-tab-background"></div>
      <div class="chrome-tab-content">
        <!-- 前置 icon 图标 -->
        <div class="chrome-tab-favicon"></div>
        <!-- tab name -->
        <div class="chrome-tab-title"></div>
        <!-- 被拖拽的区域 -->
        <div class="chrome-tab-drag-handle"></div>
        <!-- 关闭按钮 -->
        <div class="chrome-tab-close"></div>
      </div>
    </div>
  `;

  const defaultTapProperties = {
    title: "New tab",
    favicon: false,
  };

  let instanceId = 0;

  class ChromeTabs {
    constructor() {
      this.draggabillies = [];
      this.key = `chrome-tabs-${new Date().valueOf()}`;
    }

    // 标识key 类名
    key;
    // 盒子
    el;

    // 初始化
    init(el) {
      this.el = el;
      el.classList.add(this.key);
      this.instanceId = instanceId;
      this.el.setAttribute("data-chrome-tabs-instance-id", this.instanceId);
      instanceId += 1;

      this.setupCustomProperties();
      this.setupStyleEl();
      this.setupEvents();
      this.layoutTabs();
      this.setupDraggabilly();
    }

    // 触发自定义事件
    emit(eventName, data) {
      this.el.dispatchEvent(new CustomEvent(eventName, { detail: data }));
    }

    // 样式设置
    setupCustomProperties() {
      this.el.style.setProperty("--tab-content-margin", `${TAB_CONTENT_MARGIN}px`);
    }

    // 插入样式
    setupStyleEl() {
      this.styleEl = document.createElement("style");
      this.el.appendChild(this.styleEl);
    }
    // 添加事件处理
    setupEvents() {
      window.addEventListener("resize", (_) => {
        this.cleanUpPreviouslyDraggedTabs();
        this.layoutTabs();
      });

      this.el.addEventListener("dblclick", (event) => {
        if ([this.el, this.tabContentEl].includes(event.target)) this.addTab();
      });

      this.tabEls.forEach((tabEl) => this.setTabCloseEventListener(tabEl));
    }

    // 全部的选项卡
    get tabEls() {
      return Array.prototype.slice.call(
        this.el.querySelectorAll(`.${this.key} .chrome-tab`)
      );
    }

    // 选项卡盒子
    get tabContentEl() {
      return this.el.querySelector(`.${this.key} .chrome-tabs-content`);
    }
    // 选项卡宽度
    get tabContentWidths() {
      const numberOfTabs = this.tabEls.length;

      const tabsContentWidth = this.tabContentEl.clientWidth;
      const tabsCumulativeOverlappedWidth =
        (numberOfTabs - 1) * TAB_CONTENT_OVERLAP_DISTANCE;
      const targetWidth =
        (tabsContentWidth - 2 * TAB_CONTENT_MARGIN + tabsCumulativeOverlappedWidth) /
        numberOfTabs;
      const clampedTargetWidth = Math.max(
        TAB_CONTENT_MIN_WIDTH,
        Math.min(TAB_CONTENT_MAX_WIDTH, targetWidth)
      );
      const flooredClampedTargetWidth = Math.floor(clampedTargetWidth);
      const totalTabsWidthUsingTarget =
        flooredClampedTargetWidth * numberOfTabs +
        2 * TAB_CONTENT_MARGIN -
        tabsCumulativeOverlappedWidth;
      const totalExtraWidthDueToFlooring = tabsContentWidth - totalTabsWidthUsingTarget;

      // TODO - Support tabs with different widths / e.g. "pinned" tabs
      const widths = [];
      let extraWidthRemaining = totalExtraWidthDueToFlooring;
      for (let i = 0; i < numberOfTabs; i += 1) {
        const extraWidth =
          flooredClampedTargetWidth < TAB_CONTENT_MAX_WIDTH && extraWidthRemaining > 0
            ? 1
            : 0;
        widths.push(
          flooredClampedTargetWidth + extraWidth > 150
            ? 150
            : flooredClampedTargetWidth + extraWidth
        );
        if (extraWidthRemaining > 0) extraWidthRemaining -= 1;
      }
      this.tabEls.forEach((el, index) => {
        if (el.getAttribute("only") === "") {
          widths[index] = 36;
        }
      });

      return widths;
    }
    // 选项卡盒子位置
    get tabContentPositions() {
      const positions = [];
      const tabContentWidths = this.tabContentWidths;

      let position = TAB_CONTENT_MARGIN;
      tabContentWidths.forEach((width, i) => {
        const offset = i * TAB_CONTENT_OVERLAP_DISTANCE;
        positions.push(position - offset);
        position += width;
      });

      return positions;
    }

    // 选项卡位置
    get tabPositions() {
      const positions = [];

      this.tabContentPositions.forEach((contentPosition) => {
        positions.push(contentPosition - TAB_CONTENT_MARGIN);
      });

      return positions;
    }

    layoutTabs() {
      const tabContentWidths = this.tabContentWidths;

      this.tabEls.forEach((tabEl, i) => {
        const contentWidth = tabContentWidths[i];
        const width = contentWidth + 2 * TAB_CONTENT_MARGIN;

        tabEl.style.width = width + "px";
        tabEl.removeAttribute("is-small");
        tabEl.removeAttribute("is-smaller");
        tabEl.removeAttribute("is-mini");

        if (contentWidth < TAB_SIZE_SMALL) tabEl.setAttribute("is-small", "");
        if (contentWidth < TAB_SIZE_SMALLER) tabEl.setAttribute("is-smaller", "");
        if (contentWidth < TAB_SIZE_MINI) tabEl.setAttribute("is-mini", "");
      });

      let styleHTML = "";
      this.tabPositions.forEach((position, i) => {
        styleHTML += `
          .chrome-tabs[data-chrome-tabs-instance-id="${
            this.instanceId
          }"] .chrome-tab:nth-child(${i + 1}) {
            transform: translate3d(${position}px, 0, 0)
          }
        `;
      });
      this.styleEl.innerHTML = styleHTML;
    }

    // 创建一个 el
    createNewTabEl() {
      const div = document.createElement("div");
      div.innerHTML = tabTemplate;
      return div.firstElementChild;
    }

    // 添加一个tab
    addTab(
      tabProperties,
      { animate = true, background = false, id = new Date().valueOf() } = {}
    ) {
      const tabEl = this.createNewTabEl();

      if (animate) {
        tabEl.classList.add("chrome-tab-was-just-added");
        setTimeout(() => tabEl.classList.remove("chrome-tab-was-just-added"), 500);
      }
      tabEl.setAttribute("index", id);
      tabProperties = Object.assign({}, defaultTapProperties, tabProperties);
      this.tabContentEl.appendChild(tabEl);
      this.setTabCloseEventListener(tabEl);
      this.updateTab(tabEl, tabProperties);
      this.emit("tabAdd", { tabEl });
      console.log(this.tabContentEl);
      this.emit("saveData",'tabAdd');
      if (!background) this.setCurrentTab(tabEl);
      this.cleanUpPreviouslyDraggedTabs();
      this.layoutTabs();
      this.setupDraggabilly();
    }

    // 添加close 事件
    setTabCloseEventListener(tabEl) {
      tabEl
        .querySelector(`.${this.key} .chrome-tab-close`)
        .addEventListener("click", (_) => this.removeTab(tabEl));
    }

    // 获取激活盒子
    get activeTabEl() {
      return this.el.querySelector(`.${this.key} .chrome-tab[active]`);
    }
    // 获取激活盒子 id
    get current() {
      return this.activeTabEl ? this.activeTabEl.getAttribute("index") : -1;
    }

    // 是否有激活盒子
    hasActiveTab() {
      return !!this.activeTabEl;
    }
    // 设置激活盒子
    setCurrentTab(tabEl) {
      const activeTabEl = this.activeTabEl;
      if (typeof tabEl === "string" || typeof tabEl === "number") {
        tabEl = this.el.querySelector(`.${this.key} .chrome-tab[index='${tabEl}']`);
      }
      if (!tabEl) {
        if (activeTabEl) activeTabEl.removeAttribute("active");
        return;
      }
      if (activeTabEl === tabEl) return;
      if (activeTabEl) activeTabEl.removeAttribute("active");
      tabEl.setAttribute("active", "");
      this.emit("activeTabChange", { tabEl });
    }

    // 移除tab
    removeTab(tabEl) {
      this.emit("saveData");

      if (typeof tabEl === "string" || typeof tabEl === "number") {
        tabEl = this.el.querySelector(`.${this.key} .chrome-tab[index='${tabEl}']`);
      }
      if (!tabEl) return;
      if (tabEl === this.activeTabEl) {
        if (tabEl.nextElementSibling) {
          this.setCurrentTab(tabEl.nextElementSibling);
        } else if (tabEl.previousElementSibling) {
          this.setCurrentTab(tabEl.previousElementSibling);
        }
      }
      tabEl.parentNode.removeChild(tabEl);
      this.emit("tabRemove", { tabEl });
      this.cleanUpPreviouslyDraggedTabs();
      this.layoutTabs();
      this.setupDraggabilly();
    }

    updateTab(tabEl, tabProperties) {
      console.log('updateTab',tabEl);
      if (!(tabEl instanceof Object)) {
        this.tabEls.forEach((el) => {
          if (el.getAttribute("index") == tabEl) {
            tabEl = el;
          }
        });
      }

      tabEl.querySelector(`.${this.key} .chrome-tab-title`).textContent =
        tabProperties.title;

      const faviconEl = tabEl.querySelector(`.${this.key} .chrome-tab-favicon`);
      if (tabProperties.favicon) {
        // faviconEl.style.backgroundImage = `url('${tabProperties.favicon}')`
        faviconEl.innerHTML = setIcon(tabProperties);
        faviconEl.removeAttribute("hidden", "");
      } else {
        faviconEl.setAttribute("hidden", "");
        faviconEl.removeAttribute("style");
      }

      const closeEl = tabEl.querySelector(`.${this.key} .chrome-tab-close`);

      if (tabProperties.noClose || tabProperties.only) {
        closeEl.setAttribute("hidden", "");
      } else {
        closeEl.removeAttribute("hidden", "");
      }
      const titleEl = tabEl.querySelector(`.${this.key} .chrome-tab-title`);

      if (tabProperties.only) {
        titleEl.setAttribute("hidden", "");
        tabEl.setAttribute("only", "");
      } else {
        titleEl.removeAttribute("hidden", "");
        tabEl.removeAttribute("only", "");
      }

      if (tabProperties.id) {
        tabEl.setAttribute("data-tab-id", tabProperties.id);
      }
    }

    cleanUpPreviouslyDraggedTabs() {
      this.tabEls.forEach((tabEl) =>
        tabEl.classList.remove("chrome-tab-was-just-dragged")
      );
    }

    setupDraggabilly() {
      const tabEls = this.tabEls;
      const tabPositions = this.tabPositions;

      if (this.isDragging) {
        this.isDragging = false;
        this.el.classList.remove("chrome-tabs-is-sorting");
        this.draggabillyDragging.element.classList.remove("chrome-tab-is-dragging");
        this.draggabillyDragging.element.style.transform = "";
        this.draggabillyDragging.dragEnd();
        this.draggabillyDragging.isDragging = false;
        this.draggabillyDragging.positionDrag = noop; // Prevent Draggabilly from updating tabEl.style.transform in later frames
        this.draggabillyDragging.destroy();
        this.draggabillyDragging = null;
      }

      this.draggabillies.forEach((d) => d.destroy());

      tabEls.forEach((tabEl, originalIndex) => {
        const originalTabPositionX = tabPositions[originalIndex];
        const draggabilly = new Draggabilly(tabEl, {
          axis: "x",
          handle: ".chrome-tab-drag-handle",
          containment: this.tabContentEl,
        });

        this.draggabillies.push(draggabilly);

        draggabilly.on("pointerDown", (_) => { 

          this.emit("saveData"); 
          setTimeout(() => {
            this.setCurrentTab(tabEl);
          }, 100);
        });

        draggabilly.on("dragStart", (_) => {
          this.isDragging = true;
          this.draggabillyDragging = draggabilly;
          tabEl.classList.add("chrome-tab-is-dragging");
          this.el.classList.add("chrome-tabs-is-sorting");
        });

        draggabilly.on("dragEnd", (_) => {
          this.isDragging = false;
          const finalTranslateX = parseFloat(tabEl.style.left, 10);
          tabEl.style.transform = `translate3d(0, 0, 0)`;

          // Animate dragged tab back into its place
          requestAnimationFrame((_) => {
            tabEl.style.left = "0";
            tabEl.style.transform = `translate3d(${finalTranslateX}px, 0, 0)`;

            requestAnimationFrame((_) => {
              tabEl.classList.remove("chrome-tab-is-dragging");
              this.el.classList.remove("chrome-tabs-is-sorting");

              tabEl.classList.add("chrome-tab-was-just-dragged");

              requestAnimationFrame((_) => {
                tabEl.style.transform = "";

                this.layoutTabs();
                this.setupDraggabilly();
              });
            });
          });
        });

        draggabilly.on("dragMove", (event, pointer, moveVector) => {
          // Current index be computed within the event since it can change during the dragMove
          const tabEls = this.tabEls;
          const currentIndex = tabEls.indexOf(tabEl);

          const currentTabPositionX = originalTabPositionX + moveVector.x;
          const destinationIndexTarget = closest(currentTabPositionX, tabPositions);
          const destinationIndex = Math.max(
            0,
            Math.min(tabEls.length, destinationIndexTarget)
          );

          if (currentIndex !== destinationIndex) {
            this.animateTabMove(tabEl, currentIndex, destinationIndex);
          }
        });
      });
    }

    animateTabMove(tabEl, originIndex, destinationIndex) {
      if (destinationIndex < originIndex) {
        tabEl.parentNode.insertBefore(tabEl, this.tabEls[destinationIndex]);
      } else {
        tabEl.parentNode.insertBefore(tabEl, this.tabEls[destinationIndex + 1]);
      }
      this.emit("tabReorder", { tabEl, originIndex, destinationIndex });
      this.layoutTabs();
    }
  }

  return ChromeTabs;
});
